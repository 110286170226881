// Margins
.margin-top-10 {margin-top: 10px;}
.margin-top-20 {margin-top: 20px !important;}

// Utils
.pull-left {margin-right: auto;}
.pull-right {margin-left: auto;}

// Font styles
i {font-style: italic !important;}
b {font-weight: bold !important;}

// Transformations
.rotate-90 {transform: rotate(90deg);}

// Simple grid
.row {width: 100%; display: flex; flex-wrap: wrap;}
.row.vertical {flex-direction: column;}
.row .col-33 {width: calc(100%/3);}
.row .col-40 {width: 40%;}
.row .col-50 {width: 50%;}
.row .col-60 {width: 60%;}
.row .col-66 {width: calc(200%/3);}
.row .col-100 {width: 100%;}