// Store Status Badge
.store-status-badge {font-weight: 600;}
.store-status-badge.open {color: #14dca9; font-size: 13px;}
.store-status-badge.closed {color: $red1; font-size: 14px;}

// Spinner
.bp3-spinner.intent-secondary .bp3-spinner-head {stroke: $magenta3;}

// Cards
.card {padding: 16px; border-radius: 8px; border: 1px solid #efefef; background-color: #FFF; box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .10);}
.card .card-header {display: flex; align-items: center; margin-bottom: .5rem; font-size: 1.15rem; }
.card .card-header h3 {font-weight: 700; margin-right: .5rem;}
.card .card-header .icon {margin-right: .5rem; background-color: $blue1; color: #FFF; padding: 3px; border-radius: 2px; height: 20px; width: 12px;}
.card .card-header .card-btn {margin-left: auto;}
.card header h1 {margin-bottom: .25rem; color: #2D2D2D; font-size: 1.75rem; font-weight: 700; display: block; margin-bottom: .25rem;}

// Lists
.kl-list .kl-list-item {display: flex; cursor: pointer; align-items: center; font-size: 14px; font-weight: 400; min-height: 1.75rem; justify-content: space-between;}
.kl-list .kl-list-item.active {color: $blue2;}
.kl-list .kl-list-item .item-text {display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.kl-list .kl-list-item .item-action {margin-left: auto;}
.kl-list .kl-list-item .item-icon {margin-right: .5rem;}
.kl-list .kl-list-item .bp3-tag {flex-shrink: 0}

// Accordions
.accordion {padding: 16px; border-radius: 8px; background-color: #FFF; box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .16);}
.accordion .accordion-header {display: flex; align-items: center; font-size: 18px; font-weight: 800; cursor: pointer;}
.accordion .accordion-header .header-content {display: flex; align-items: center; flex: 1;}
.accordion .accordion-header .header-content .icon {margin-right: 10px; color: rgba(0, 0, 0, 0.54); transition: transform 300ms linear;}
.accordion .accordion-header .pull-right {margin-left: auto;}
.accordion.open .accordion-header .icon {transform: rotate(180deg);}

// Phone field
.phone-field.PhoneInput--focus {box-shadow: $bp3-input-focus-box-shadow;}
.phone-field.bp3-intent-danger.PhoneInput--focus {box-shadow: $bp3-input-focus-box-shadow-danger;}
.phone-field .PhoneInputInput {border: none; padding: 0; height: 1.5rem;}

// Locations selected toast
.items-selected-toast {width: 600px;}
.items-selected-toast.bp3-toast {align-items: center;}
.items-selected-toast .bp3-toast-message {display: flex; align-items: center; padding-right: 0;}
.items-selected-toast .bp3-toast-message .selected-count {display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; border-radius: 4px; padding: 3px 7px; margin-right: 7px; background-color: #FFF; color: $blue1; font-size: 25px;}
.items-selected-toast .bp3-toast-message h3 {font-size: 18px;}
.items-selected-toast .bp3-button-group {margin-left: auto;}
.items-selected-toast .bp3-button-group .bp3-button.action-button {color: #FFF !important;}

// Pagination Row
.pagination-row {display: flex !important; align-items: center; justify-content: space-between; width: 100%;}

// Rating stars
.rating-stars {color: #FFB400; font-size: 16px; display: flex; align-items: center;}
.star {display: block; margin-right: 3px;}

// Order status tag
.bp3-tag.order-status-tag {font-size: 1em;}
.order-status-tag.is-received {background-color: $color-order-status-received;}
.order-status-tag.is-preparing {background-color: $color-order-status-preparing;}
.order-status-tag.is-ready {background-color: $color-order-status-ready;}
.order-status-tag.is-delivered {background-color: $color-order-status-delivered;}
.order-status-tag.is-cancelled-by-customer {background-color: $color-order-status-cancelled-by-customer;}
.order-status-tag.is-cancelled-by-store {background-color: $color-order-status-cancelled-by-store;}
.order-status-tag.is-partially-refunded {background-color: $color-order-status-partially-refunded;}
.order-status-tag.is-refunded {background-color: $color-order-status-refunded;}

// Order status menu item
.bp3-menu-item.order-status {color: #FFF; margin-bottom: 5px; height: 50px; display: flex; flex-direction: row-reverse; align-items: center; justify-content: center; transition: background-color 300ms ease;}
.bp3-menu-item.order-status.is-received {background-color: $color-order-status-received;}
.bp3-menu-item.order-status.is-received:hover {background-color: $color-order-status-received--hover; color: #FFF;}
.bp3-menu-item.order-status.is-received .bp3-icon {color: #FFF;}
.bp3-menu-item.order-status.is-preparing {background-color: $color-order-status-preparing;}
.bp3-menu-item.order-status.is-preparing:hover {background-color: $color-order-status-preparing--hover; color: #FFF;}
.bp3-menu-item.order-status.is-preparing .bp3-icon {color: #FFF;}
.bp3-menu-item.order-status.is-ready {background-color: $color-order-status-ready;}
.bp3-menu-item.order-status.is-ready:hover {background-color: $color-order-status-ready--hover; color: #FFF;}
.bp3-menu-item.order-status.is-ready .bp3-icon {color: #FFF;}
.bp3-menu-item.order-status.is-delivered {background-color: $color-order-status-delivered;}
.bp3-menu-item.order-status.is-delivered:hover {background-color: $color-order-status-delivered--hover; color: #FFF;}
.bp3-menu-item.order-status.is-delivered .bp3-icon {color: #FFF;}

// Drawer
.kl-drawer .bp3-dialog-body {max-height: max-content;}
