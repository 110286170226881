.table {width: 100%; }

// Modifiers
.table--striped .table__body .table__row:nth-child(odd) {background-color: $light-gray5;}
.table--condensed .table__row > * {font-size: .75rem; padding: .5rem} 
.table--empty {padding: 2rem 1rem; text-align: center; background-color: white;}

// Elements
.table__head .table__row {border-bottom: 1px solid $light-gray3; font-weight: normal; background-color: white;}
.table__heading {font-weight: 600; border-left: 1px solid $light-gray3; padding: .75rem; text-align: left; vertical-align: middle;}
.table__heading:first-child {border: none}

.table__body .table__row:hover {background-color: $light-gray5 !important;}
.table__cell {padding: .75rem; text-align: left; vertical-align: middle;}
.table__cell:not(:last-child) {word-break: break-word;}

.table__pagination {background-color: $light-gray5; padding: .5rem; font-size: .75rem; display: flex !important; align-items: center; justify-content: space-between; width: 100%;}
.table__actions {text-align: right; border: none}
