@import "./scenes/orders-history";
@import "./scenes/dashboard";
@import "./scenes/login";
@import "./scenes/users";
@import "./scenes/support";
@import "./scenes/privacy-policy";
@import "./scenes/qr-generator";
@import "./scenes/fatal-error";
@import "./scenes/orders";

.scene {margin: 0; padding: 0;}
.scene.scene-content {margin: 2rem 0; padding: 0 4rem;}
.scene .scene-spinner {margin: 0 auto; text-align: center; display: block;}
.scene .scene-error {margin: 0 auto; width: 50%; text-align: center; }
.scene .scene-title {color: #2D2D2D; font-size: 1.75rem; font-weight: 700; display: block; margin-bottom: 1rem;}

.scene .scene-controls {display: flex; align-items: center; }
.scene .scene-controls .left-controls {margin-right: auto; display: flex; align-items: center;}
.scene .scene-controls .left-controls > * {margin-right: 10px;}
.scene .scene-controls .right-controls {margin-left: auto; display: flex; align-items: flex-end;}
.scene .scene-controls .right-controls > * {margin-left: 10px;}
