.store-details-scene .scene-content > .bp3-spinner {margin-top: 20px;}

// Store details scene
.store-details-scene {padding: 0; margin: 0; }
.store-details-scene .store-details-content {display: flex; width: 100%; padding: 0 5rem}
.store-details-scene .store-details-content .side-content {width: 300px; overflow: auto; padding: 2rem 1rem 1rem 0; height: fit-content; position: sticky;}
.store-details-scene .store-details-content .page-content {flex: 1; overflow: auto; padding: 2rem 0; }

// Store details header
.store-details-header {width: 100%; position: fixed; top: 0; z-index: 10; padding-top: 1.75rem; background-color: #FFF; box-shadow: 0 2px 5px 0 rgba(222, 228, 240, .6);}

.store-details-header .back-button {position: absolute; left: 50px; top: 50%; transform: translateY(-50%); color: #8392A7 !important;}

.store-details-header .details-content {width: 100%; display: flex; justify-content: center; padding-right: 28rem; align-items: center; }
.store-details-header .details-content .store-image { border: 1px solid lightgrey;  vertical-align: middle; width: 3.5rem; height: 3.5rem; object-fit: cover; border-radius: 50%; margin-right: 1rem;}
.store-details-header .details-content .store-titles h1 {font-size: 24px; font-weight: 700;}
.store-details-header .details-content .store-titles .store-text {margin-top: .25rem; font-size: 13px; font-weight: 600; color: #8392a7; width: 550px; white-space: nowrap; letter-spacing: 0; line-height: 13px; overflow: hidden; text-overflow: ellipsis;}

.store-details-header .store-tabs {display: flex; justify-content: center; margin-top: 20px; padding-right: 21rem;}
.store-details-header .store-tabs .store-tab {font-size: 17px; font-weight: 600; padding: 10px 20px;}

.store-tabs .store-tab.bp3-tab {padding: .5rem 0; margin: 0 1.5rem}
.store-tabs .store-tab.bp3-tab:hover {color: #0D02FF; }
.store-tabs .store-tab.bp3-tab[aria-selected="true"] {color: #0D02FF; opacity: 1;}
.store-tabs .store-tab.bp3-tab[aria-selected="true"]:hover {color: #0D02FF; opacity: 1;}
.store-tabs .bp3-tab-indicator {background-color: #0D02FF !important;}

.bp3-tab-indicator {background-color: transparent !important;}