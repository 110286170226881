.order-details-dialog {padding: 0 !important; border-radius: 0 0 .5rem .5rem;}
.order-details-dialog .dialog-title {padding: .5rem 0; text-align: left;}
.order-details-dialog .dialog-title label {font-size: 16px; font-weight: 800;}
.order-details-dialog .dialog-title p {font-size: 12px; font-weight: 500; color: $gray1;}
.order-details-dialog .bp3-dialog-body {margin: 0; padding: 0; border-radius: 0 0 .5rem .5rem;}
.order-details-dialog .section-title {font-weight: 600; display: flex; align-items: center; padding: 1rem 1.5rem .5rem 1.5rem; color: $gray1; background-color: #FAFAFF; border-bottom: 1px solid #E7ECF4;}
.order-details-dialog .section-title:not(:first-child) {border-top: 1px solid #E7ECF4;}
.order-details-dialog .location-section {padding: 1rem 1.5rem 0 1.5rem; background-color: #FFF; display: flex; align-items: flex-start; }
.order-details-dialog .location-section > * {width: 50%;}
.order-details-dialog .location-section .title {margin-bottom: .5rem;}
.order-details-dialog .location-section .status-updated-at {margin-top: .5rem; margin-bottom: 0;}
.order-details-dialog .location-section .pickup-info {font-style: italic;}
.order-details-dialog .location-section .left-content .name {word-break: break-word;}

.order-details-dialog .order-items-list {padding: 1rem 1.5rem; background-color: #FFF;}
.order-details-dialog .order-items-list .order-item-row {padding: .75rem 0; border-bottom: 1px solid #E7ECF4;}
.order-details-dialog .order-items-list .order-item-row:first-child {padding-top: 0;}
.order-details-dialog .order-items-list .order-item-row:last-child {padding-bottom: 0;}
.order-details-dialog .order-items-list .order-item-row .main-line {display: flex; align-items: flex-start; font-weight: 600;}

.order-details-dialog .order-items-list .order-item-row .modifier-line {display: flex; align-items: flex-start; margin: .5rem 0; color: $gray2; font-weight: 400;}
.order-details-dialog .order-items-list .order-item-row .modifier-line:last-child {margin-bottom: 0;}
.order-details-dialog .order-items-list .order-item-row .modifier-line.is-refund {padding-top: .25rem;}

.order-details-dialog .order-items-list .order-item-row:last-child {border-bottom: none;}
.order-details-dialog .order-items-list .order-item-row .col-quantity {width: 7.5%;}
.order-details-dialog .order-items-list .order-item-row .col-title {width: 77.5%;}

.order-details-dialog .order-items-list .order-item-row .col-title.is-note { font-style: italic; color: $gray1; background-color: $light-gray4; padding: .5rem; border-radius: .25rem; }
.order-details-dialog .order-items-list .order-item-row .col-title.is-total {font-weight: bold; color: inherit;}
.order-details-dialog .order-items-list .order-item-row .col-amount {width: 15%; margin-left: .5rem; text-align: right;}
.order-details-dialog .order-items-list .order-item-row .col-amount.is-total {font-weight: bold;}
.order-details-dialog .order-items-list .order-item-row .col-amount .price-old {text-decoration: line-through;}
.order-details-dialog .order-items-list .order-item-row .main-line.is-refund .col-quantity {width: 5%;}
.order-details-dialog .order-items-list .order-item-row .main-line.is-refund .col-title {width: 60%;}
.order-details-dialog .order-items-list .order-item-row .main-line.is-refund .col-title .price-old {text-decoration: line-through;}
.order-details-dialog .order-items-list .order-item-row .main-line.is-refund .col-amount {width: 20%; text-align: left;}
.order-details-dialog .order-items-list .order-item-row .main-line.is-refund .col-refund {width: 15%; margin-left: auto;}
.order-details-dialog .payment-method-section {padding: 1rem 1.5rem; background-color: #FFF; display: flex; align-items: center;}
.order-details-dialog .payment-method-section .order-payment-method {margin: 0 1rem 0 0;}
.order-details-dialog .payment-method-section .room-charge h4 {margin-bottom: .25rem;}
.order-details-dialog .rating-section {padding: 1rem 1.5rem; background-color: #FFF; display: flex; flex-direction: column}
.order-details-dialog .rating-section .feedback-notes { font-style: italic; padding-top: .5rem;}
.order-details-dialog .bp3-dialog-footer {padding: .5rem 0; border-top: 1px solid #E7ECF4;}
