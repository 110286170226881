.login-scene {height: 100vh; display: flex; align-items: center; justify-content: center; background-color: $blue1;}
.login-form {width: 600px; padding: 0;}
.login-form .form-header {box-shadow: 0 3px 5px rgba(16, 22, 26, 0.15); padding: 20px 0;}
.login-form .form-header .form-logo {width: 50%; margin: 0 auto;}
.login-form .form-header .form-logo img {width: 100%;}
.login-form .form-body {padding: 20px 50px;}
.login-form .form-body .bp3-form-group {margin: 30px 0;}
.login-form .form-body .form-actions {text-align: center; margin-top: 10px;}
.login-form .form-body .form-actions button {padding-left: 20px; padding-right: 20px;}
.login-form .forgot-password-callout {margin-top: 12px;}
