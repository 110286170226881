.kl-dialog {}

.kl-dialog .bp3-dialog-header {text-align: center; box-shadow: 0 3px 5px rgba(16, 22, 26, 0.15);}

.kl-dialog .bp3-dialog-footer {display: flex; justify-content: flex-end;}
.kl-dialog .bp3-dialog-footer.center {justify-content: center;}
.kl-dialog .bp3-dialog-footer > * {margin-left: 5px;}  


.kl-dialog .orders-list {height: 75vh; overflow: auto; padding: 4px;}

.kl-dialog .notifications-dialog-form {display: flex; justify-content: space-between;}