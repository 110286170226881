$background: #fafafa;

// Variable overrides
$blue1: #0D02FF;
$blue2: #0B00E0;
$blue3: #0900B8;
$blue4: #07008F;
$blue5: #050066;

$gray1: #6b6b6b;
$gray2: #898989;
$gray3: #a6a6a6;
$gray4: #b5b5b5;
$gray5: #c4c4c4;

$light-gray1: #d6d6d6;
$light-gray2: #dedede;
$light-gray3: #e6e6e6;
$light-gray4: #efefef;
$light-gray5: #f7f7f7;

$red1: #FF373C;
$red2: #FF333A;
$red3: #FF1F26;
$red4: #FF0A12;
$red5: #F50008;

$green1: #0FAA81;
$green2: #0FAA81;
$green3: #14DDA9;
$green4: #A1F1DD;
$green5: #A1F1DD;

$magenta1: #F14173;
$magenta2: #EF2E65;
$magenta3: #ED1652;
$magenta4: #E4114D;
$magenta5: #D11046;

$pt-intent-primary: $blue1;
$pt-intent-success: $green3;
$pt-intent-warning: $orange3;
$pt-intent-danger: $red1;

$bp3-input-box-shadow: 0 0 0 0 rgba(13, 2, 255, 0), 0 0 0 0 rgba(13, 2, 255, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.1);
$bp3-input-box-shadow-danger: 0 0 0 0 rgba(255, 55, 60, 0), 0 0 0 0 rgba(255, 55, 60, 0), inset 0 0 0 1px $red1, inset 0 0 0 1px rgba(16, 22, 26, .15), inset 0 1px 1px rgba(16, 22, 26, .2);
$bp3-input-focus-box-shadow: 0 0 0 1px $blue1, 0 0 0 3px rgba(19, 124, 189, .3), inset 0 1px 1px rgba(16, 22, 26, .2);
$bp3-input-focus-box-shadow-danger: 0 0 0 1px $red1, 0 0 0 3px rgba(255, 55, 60, .3), inset 0 1px 1px rgba(16, 22, 26, .2);

// Order status colors
$color-order-status-received: #2A4874;
$color-order-status-received--hover: #223859;
$color-order-status-preparing: #FEA76E;
$color-order-status-preparing--hover: #DA9262;
$color-order-status-ready: #1CC1EB;
$color-order-status-ready--hover: #1593B2;
$color-order-status-delivered: #0FAD85;
$color-order-status-delivered--hover: #139675;
$color-order-status-cancelled-by-customer: #F60357;
$color-order-status-cancelled-by-customer--hover: #D1054D;
$color-order-status-cancelled-by-store: #F60357;
$color-order-status-cancelled-by-store--hover: #D1054D;
$color-order-status-partially-refunded: #F60357;
$color-order-status-partially-refunded--hover: #D1054D;
$color-order-status-refunded: #F60357;
$color-order-status-refunded--hover: #D1054D;