.app-navigator {width: 220px; display: flex; flex-direction: column; color: #fff;}
.app-navigator .navigator-base {width: 220px; background: linear-gradient(315deg, #0a00d2 35.57%, #0D02FF 92.42%); overflow: visible; padding: 1rem 0 .25rem 0;}

.app-navigator .navigator-header {width: 100%; position: relative;}
.app-navigator .navigator-header .app-logo {width: 100%; padding: 0 1.5rem 0 .5rem;}
.app-navigator .navigator-header .app-logo img {width: 100%;}
.app-navigator .navigator-header .navigator-handle {position: absolute; top: calc(50% - .2rem); right: 0; transform: translate(50%, -50%); background-color: #06017f; color: white; border-radius: 100%; height: 2rem; width: 2rem; border: .2rem solid #fff; display: flex; align-items: center; justify-content: center;}
.app-navigator .navigator-header .navigator-handle:hover {cursor: pointer;}

.app-navigator .navigator-list {padding: 1rem 0;}

.app-navigator .navigator-footer {flex: 1; display: flex; flex-direction: column; justify-content: flex-end;}
.app-navigator .navigator-footer .user-title {color: #fff; font-weight: 800; font-size: 14px; cursor: pointer; margin-bottom: 5px; display: flex; align-items: center; justify-content: center; width: 100%;}
.app-navigator .navigator-footer .user-title .icon {margin-left: 5px;}

.app-navigator .navigator-footer .extra-links {color: #fff; text-align: center; font-size: 10px; margin-bottom: 10px;}
.app-navigator .navigator-footer .extra-links a {color: #fff; text-decoration: none;}

.app-navigator .navigator-footer .copyright {color: #fff; text-align: center; font-size: 10px; margin-bottom: 20px;}
.app-navigator .navigator-footer .copyright a {color: #fff; text-decoration: none;}

.navigator-list-item {position: relative; font-size: 1rem; color: #fff; padding: .75rem 1rem; opacity: .65; text-decoration: none; display: flex;}
.navigator-list-item:hover{color: #fff; opacity: 1; text-decoration: none;}
.navigator-list-item--active {opacity: 1; }
.navigator-list-item--active::before {display: block;content: "";position: absolute;top: .5rem;left: 0;width: .25rem;height: 1.5rem;border-radius: 0 .15rem .15rem 0;transition: background-color 0.15s linear 0s;background-color: #06017F;}

.navigator-list-item__icon {min-width: 2rem; margin-right: .5rem; text-align: center;}


.navigator-list-item:hover {text-decoration: none;}
.navigator-list-item button.list-item {color: $light-gray1; font-size: 15px; font-weight: 300; padding: .75rem 1.5rem; border-radius: 0; font-weight: 400;}
.navigator-list-item button.list-item:hover {color: $light-gray5; background-color: transparent;}

.navigator-list-item.active::before {display: block;content: "";position: absolute;top: .5rem;left: 0;width: .25rem;height: 1.5rem;border-radius: 0 .15rem .15rem 0;transition: background-color 0.15s linear 0s;background-color: #06017F;}
.navigator-list-item button.list-item * {cursor: pointer;}
.navigator-list-item button.list-item .icon {width: 12px; height: 12px; margin: 0 16px 0 0; min-width: auto;}

