.omnivore-categories-drawer {height: 100vh;}
.omnivore-categories-drawer .bp3-drawer-body {display: flex; flex-direction: column; padding: 20px;}
.omnivore-categories-drawer .drawer-controls {display: flex; align-items: center; justify-content: space-between; margin-top: 15px;}
.omnivore-categories-drawer .drawer-controls > * {flex: 1;}
.omnivore-categories-drawer .drawer-controls > * + * {margin-left: 10px;}
.omnivore-categories-drawer .omnivore-menu-items-list {margin-top: 20px; flex: 1; overflow: auto;}
.omnivore-categories-drawer .omnivore-menu-items-list .omnivore-menu-item {display: flex; align-items: center; margin-bottom: 5px; border-bottom: 1px solid #CFD0D4; padding: 8px 0;}
.omnivore-categories-drawer .omnivore-menu-items-list .omnivore-menu-item.disabled .menu-item-name {color: $gray4;}
.omnivore-categories-drawer .omnivore-menu-items-list .omnivore-menu-item.disabled .menu-item-price {color: $gray4;}
.omnivore-categories-drawer .omnivore-menu-items-list .omnivore-menu-item .menu-item-name {margin-left: 20px; font-size: 14px; font-weight: 600;}
.omnivore-categories-drawer .omnivore-menu-items-list .omnivore-menu-item .menu-item-price {margin-left: auto; font-size: 14px; font-weight: 500;}