.product-upsert-drawer {}
.product-upsert-drawer section {margin-bottom: 2rem}
.product-upsert-drawer section h5 {font-size: 1rem; font-weight: 800;  margin-bottom: .75rem;}
.product-upsert-drawer .no-modifiers-message { margin-bottom: .75rem; font-style: italic;}
.product-upsert-drawer section.general .price-tax-container { display: grid; grid-template-columns: 1fr 1fr; column-gap: 1rem;}
.product-upsert-drawer section.general .product-linked { margin-bottom: 16px}

.product-upsert-drawer section.advanced header { display: flex; align-items: center; justify-content: space-between; cursor: pointer;}
.product-upsert-drawer section.advanced header .bp3-icon { transition: transform 250ms ease-in-out;}
.product-upsert-drawer section.advanced.expanded header .bp3-icon { transform: rotate(-180deg) }

.product-upsert-drawer .modifier-group { background-color: $light-gray5; border: 1px solid $light-gray3; padding: 1rem; margin-bottom: 1rem; border-radius: .25rem;}
.product-upsert-drawer .modifier-group header { border-bottom: 1px solid $light-gray3; padding-bottom: .5rem;  margin-bottom: .5rem; display: grid; grid-template-columns: auto 5.5fr 2fr auto; grid-column-gap: 8px; align-items: center;}
.product-upsert-drawer .modifier-group header .modifier-group-title input { font-size: 1.15rem; }
.product-upsert-drawer .modifier-group header .modifier-group-description { width: 100%;}
.product-upsert-drawer .modifier-group header .handle {margin-top: -0.25rem; color: $gray1}
.product-upsert-drawer .modifier-group .add-extra { margin-bottom: 1rem; }
.product-upsert-drawer .modifier-group .modifiers-selection-limits { width: 50%; display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 1rem; }
.product-upsert-drawer .modifier-group .modifiers-min-count-error { color: $red2 }
.product-upsert-drawer .modifier-group .editable-text > input { background-color: $light-gray5; font-weight: 600; padding: 0; transition: all 200ms ease;}
.product-upsert-drawer .modifier-group .editable-text > input:not(:focus) { box-shadow: none;}
.product-upsert-drawer .modifier-group .bp3-intent-danger.editable-text > input { padding: 0 .5rem; box-shadow: 0 0 0 0 rgba(255,55,60, 0), 0 0 0 0 rgba(255,55,60, 0), inset 0 0 0 1px #ff373c, inset 0 0 0 1px rgba(16, 22, 26, .15), inset 0 1px 1px rgba(16,22,26, .2); }
.product-upsert-drawer .modifier-group .editable-text > input:focus { background-color: white;  padding: 0 .5rem}

.product-upsert-drawer .single-modifier { display: grid; grid-template-columns: auto auto 2.4fr 1fr auto; grid-column-gap: .5rem; align-items: center; margin: .5rem 0;}
.product-upsert-drawer .single-modifier .editable-title { font-weight: bold; margin: 0 .5rem; height: 100%; flex-grow: 1; height: 1.5rem; line-height: 1.5rem;}
.product-upsert-drawer .single-modifier .modifier-price-input { max-width: 100%;}
.product-upsert-drawer .single-modifier .handle {margin-top: -0.25rem; color: $gray1}
