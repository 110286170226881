.order-card {flex-shrink: 0; padding: 16px; border-radius: 4px; background-color: #FFF; box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .16); margin-bottom: 5px;}

.order-card .order-header {display: flex; align-items: center; border-bottom: 1px solid $light-gray2; padding-bottom: .25rem; margin-bottom: .5rem;}
.order-card .order-header .order-title {font-weight: bold; font-size: 16px; margin-right: auto; word-break:  break-word;}
.order-card .order-header .more-actions {margin-left: .5rem;}
.order-card .order-header .order-overdue-tag {min-width: auto;}

.order-card .order-additional-info {border-bottom: 1px solid $light-gray2; padding-bottom: .5rem; margin-bottom: .5rem;}
.order-card .order-additional-info .customer-info {font-weight: 600; margin-top: .25rem;}
.order-card .order-additional-info .pickup-info {font-style: italic; margin-top: .25rem;}

.order-card .location-attributes {padding-bottom: .25rem; margin-bottom: .75rem; border-bottom: 1px solid $light-gray2;}
.order-card .location-attributes .location-attribute {padding-bottom: .25rem; margin-bottom: .25rem;}
.order-card .location-attributes .location-attribute .location-attribute-name {font-weight: 600;}

.order-card .order-product-line {display: flex; align-items: flex-start; margin-bottom: .5rem;}
.order-card .order-product-line .item-quantity {width: 10%; font-weight: 600;}
.order-card .order-product-line .item-description {width: 90%;}
.order-card .order-product-line .item-description h1 {margin-bottom: .25rem; font-weight: 600;}
.order-card .order-product-line .item-description .modifier-group-section {padding-left: .5rem; margin-bottom: .5rem; transition: border 200ms linear;}
.order-card .order-product-line .item-description .modifier-group-section.is-received {border-left: .125rem solid $color-order-status-received;}
.order-card .order-product-line .item-description .modifier-group-section.is-received:hover {border-left: .125rem solid $color-order-status-received--hover;}
.order-card .order-product-line .item-description .modifier-group-section.is-preparing {border-left: .125rem solid $color-order-status-preparing;}
.order-card .order-product-line .item-description .modifier-group-section.is-preparing:hover {border-left: .125rem solid $color-order-status-preparing--hover;}
.order-card .order-product-line .item-description .modifier-group-section.is-ready {border-left: .125rem solid $color-order-status-ready;}
.order-card .order-product-line .item-description .modifier-group-section.is-ready:hover {border-left: .125rem solid $color-order-status-ready--hover;}
.order-card .order-product-line .item-description .modifier-group-section.is-delivered {border-left: .125rem solid $color-order-status-delivered;}
.order-card .order-product-line .item-description .modifier-group-section.is-delivered:hover {border-left: .125rem solid $color-order-status-delivered--hover;}
.order-card .order-product-line .item-description .modifier-group-section h4 {color: $gray1;}
.order-card .order-product-line .item-description .modifier-group-section h4 + h4 {padding-left: .5rem; font-weight: 400;}
.order-card .order-product-line .item-description p { font-style: italic; color: $gray1; background-color: $light-gray4; padding: .5rem; border-radius: .25rem; }
.order-card .order-cta {display: flex; align-items: center; justify-content: flex-end; padding-top: .5rem; border-top: 1px solid $light-gray2;}
.order-card .order-cta .order-status {color: #FFF !important; border: none; transition: background-color 100ms linear;}
.order-card .order-cta .order-status .bp3-icon {color: #FFF;}
.order-card .order-cta .order-status.is-received {background-color: $color-order-status-received;}
.order-card .order-cta .order-status.is-received:hover {background-color: $color-order-status-received--hover;}
.order-card .order-cta .order-status.is-preparing {background-color: $color-order-status-preparing;}
.order-card .order-cta .order-status.is-preparing:hover {background-color: $color-order-status-preparing--hover;}
.order-card .order-cta .order-status.is-ready {background-color: $color-order-status-ready;}
.order-card .order-cta .order-status.is-ready:hover {background-color: $color-order-status-ready--hover;}
