@tailwind base;
@tailwind components;
@tailwind utilities;

* {font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {margin: 0; padding: 0; border: 0; font-size: 100%; vertical-align: baseline;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content: ''; content: none;}
table {border-collapse: collapse; border-spacing: 0;}
body {line-height: 1; margin: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; scrollbar-width: none; -ms-overflow-style: none; }

// *::-webkit-scrollbar {width: 0 !important; height: 0 !important; background: transparent; }

// Hide scrollbar for Chrome, Safari and Opera
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar for IE, Edge and Firefox
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;}

.clearfix::after {content: ''; display: block; clear: both;}

.center {text-align: center;}
.fw-500 {font-weight: 500 !important;}

// Remove input[type=number] arrows (they look weird in blueprint)
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
input[type=number] {-moz-appearance: textfield;}

@import url("~normalize.css");
@import "~@blueprintjs/core/lib/scss/variables";
@import "./styles/main.scss";
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/select/src/blueprint-select.scss";
@import "~@blueprintjs/datetime/src/blueprint-datetime.scss";
@import "~@blueprintjs/popover2/src/blueprint-popover2.scss";
@import url("~@blueprintjs/icons/lib/css/blueprint-icons.css");
@import "./styles/bp3-overrides";


p {margin: 0;}

@media print {
  .no-print {
      display:none;
  }
}