.settings-form {position: relative; margin-bottom: 20px;}
.settings-form .form-actions {position: absolute; right: 20px; top: 20px;}
.settings-form .form-body {max-width: 500px;}
.settings-form .card-title {font-size: 1.25rem;}
.settings-form.card .card-header {margin-bottom: 1rem;}

.timeframes-table {margin-top: 20px;}
.timeframes-table .timeframe-row {margin-bottom: 5px;}

.taxes-table {margin-top: 20px;}
.taxes-table .tax-row {margin-bottom: 5px;}

.revenue-groups-table .revenue-group-row {margin-bottom: 5px;}
