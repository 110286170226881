// Timeframe row
.timeframe-row {width: 100%; padding: 12px; border: 1px solid #E7ECF4 ; border-radius: 4px; display: flex; align-items: center;}
.timeframe-row > * {display: flex; align-items: center;}
.timeframe-row > * p {margin: 0;}
.timeframe-row > * p span {font-weight: 600;}
.timeframe-row .status-col {width: 10%; justify-content: center;}
.timeframe-row .status-col .bp3-switch {margin: 0;}
.timeframe-row .name-col {width: 20%;}
.timeframe-row .time-col {width: 20%;}
.timeframe-row .days-col {width: 20%;}
.timeframe-row .actions-col {width: 20%; display: flex; justify-content: flex-end;}

// Timeframe dialog
.timeframe-dialog {}
.timeframe-dialog .time-inputs {display: flex; gap: 1rem}
.timeframe-dialog .time-inputs > * {width: 50%;}

.timeframe-row .bp3-control-indicator {
    display: hidden;
}