.scene.scene-content.scene-orders {margin-top: 2rem; margin-bottom: 0;}
.scene-orders .scene-header {height: calc(10vh - 1rem);}

.order-statuses-list {height: calc(90vh - 1rem); display: flex; align-items: stretch; border-radius: 4px;}
.order-statuses-list .order-status-item {flex: 1; display: flex; flex-direction: column; align-items: stretch; justify-content: flex-start; margin: 0 2px; padding: 0 0 4px; border-radius: 4px; min-width: 200px; max-width: 25%; background-color: $light-gray1;}
.order-statuses-list .order-status-item:first-child {margin: 0 2px 0 0;}
.order-statuses-list .order-status-item:last-child {margin: 0 0 0 2px;}

.order-statuses-list .order-status-item .status-header {height: 40px; flex-shrink: 0; display: flex; align-items: center; justify-content: space-between; border-radius: 4px 4px 0 0; padding: 0 16px;}
.order-statuses-list .order-status-item .status-header.is-received {background-color: $color-order-status-received;}
.order-statuses-list .order-status-item .status-header.is-received .total-orders .total-orders-count {background-color: #FFF; color: $color-order-status-received;}
.order-statuses-list .order-status-item .status-header.is-preparing {background-color: $color-order-status-preparing;}
.order-statuses-list .order-status-item .status-header.is-preparing .total-orders .total-orders-count {background-color: #FFF; color: $color-order-status-preparing;}
.order-statuses-list .order-status-item .status-header.is-ready {background-color: $color-order-status-ready;}
.order-statuses-list .order-status-item .status-header.is-ready .total-orders .total-orders-count {background-color: #FFF; color: $color-order-status-ready;}
.order-statuses-list .order-status-item .status-header.is-delivered {background-color: $color-order-status-delivered;}
.order-statuses-list .order-status-item .status-header.is-delivered .total-orders .total-orders-count {background-color: #FFF; color: $color-order-status-delivered;}
.order-statuses-list .order-status-item .status-header .status-title {color: #FFF; font-size: 16px; font-weight: bold;}
.order-statuses-list .order-status-item .status-header .total-orders {color: #FFF; font-size: 14px; font-weight: 400; display: flex; align-items: center;}
.order-statuses-list .order-status-item .status-header .total-orders .total-orders-count {height: 24px; width: 24px; padding: 2px; background-color: #FFF; color: #000; border-radius: 100%; font-weight: bold; display: flex; align-items: center; justify-content: center; margin-left: 4px;}


.order-statuses-list .order-status-item .orders-list {flex: 1; padding: 4px; display: flex; flex-direction: column; align-items: stretch; justify-content: flex-start; overflow: auto; background-color: $light-gray4;}