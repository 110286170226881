.dashboard-scene {width: 100%; display: flex; flex-direction: column; align-items: flex-start;}

.dashboard-scene .top-navigation {display: flex; justify-content: space-between; width: 100%; margin-bottom: 1rem;}
.dashboard-scene .top-navigation .dashboard-title {font-size: 28px; font-weight: bold; color: #2D2D2D;}
.dashboard-scene .top-navigation .dashboard-controls {display: flex; align-items: center; justify-content: flex-end;}
.dashboard-scene .top-navigation .dashboard-controls > * {display: flex; align-items: center; flex-wrap: wrap;}
.dashboard-scene .top-navigation .dashboard-controls > * > *:not(:first-child) {margin-left: 15px;}
.dashboard-scene .top-navigation .dashboard-controls > * .compare-text {color: #8191a5;}
.dashboard-scene .top-navigation .dashboard-controls > .compare-controls {margin-left: 15px; margin-top: 6px;}

.dashboard-scene .dashboard-widgets {width: 100%;}

.dashboard-widget {background-color: transparent; padding: 5px;}
.dashboard-widget .widget-container {background-color: #FFF; border-radius: 5px; box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16); padding: 12px 15px;}
.dashboard-widget .widget-spinner {color: #8392A7; width: 18px !important; height: 18px !important;}
.dashboard-widget .widget-info {color: #8392A7; margin: 0;}
.dashboard-widget .widget-error {color: #ED1652; margin: 0;}

.dashboard-widget.widget-total .widget-title {font-size: 14px; color: #8392A7; font-weight: 600; margin: 0 0 7px;}
.dashboard-widget.widget-total .widget-total-value {margin: 0; font-size: 26px; font-weight: bold; display: flex; align-items: center;}
.dashboard-widget.widget-total .widget-total-value .widget-diff-percentage {font-size: 14px; font-weight: 600; color: #14DCA9; margin: 0 0 0 8px;}
.dashboard-widget.widget-total .widget-total-value .widget-diff-percentage.negative {color: #ED1652;}

.dashboard-widget.widget-list .widget-container {padding-top: 20px;}
.dashboard-widget.widget-list .widget-title {font-size: 26px; font-weight: bold; color: #2D2D2D;  margin: 0 0 15px;}
.dashboard-widget.widget-list .list-items {display: flex; flex-direction: column;}
.dashboard-widget.widget-list .list-items .list-item {display: flex; align-items: center;}
.dashboard-widget.widget-list .list-items .list-item:not(:last-of-type) {border-bottom: 1px solid #8392A751;}
.dashboard-widget.widget-list .list-items .list-item .item-figure {height: 40px; width: 40px; border-radius: 4px; overflow: hidden; margin: 5px 20px 5px 10px; flex-shrink: 0;}
.dashboard-widget.widget-list .list-items .list-item .item-figure img {width: 100%;}
.dashboard-widget.widget-list .list-items .list-item .item-header {display: flex; flex-direction: column; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-right: 15px;}
.dashboard-widget.widget-list .list-items .list-item .item-header .item-title {color: #2D2D2D; font-size: 14px; font-weight: 600; margin: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.dashboard-widget.widget-list .list-items .list-item .item-header .item-subtitle {color: #8392A7; font-size: 12px; font-weight: 800; margin: 0; line-height: 12px;}
.dashboard-widget.widget-list .list-items .list-item .item-side-info {color: #8392A7; font-size: 14px; font-weight: 600; margin-left: auto; flex-shrink: 0;}

.dashboard-widget.widget-top-categories .widget-container {padding-top: 20px;}
.dashboard-widget.widget-top-categories .widget-title {font-size: 26px; font-weight: bold; color: #2D2D2D;  margin: 0 0 15px;}
.dashboard-widget.widget-top-categories .chart-center {margin: -30% auto 25%; text-align: center;}
.dashboard-widget.widget-top-categories .chart-center .center-title {font-size: 24px; font-weight: bold;}
.dashboard-widget.widget-top-categories .chart-center .center-subtitle {font-size: 16px; font-weight: 400; color: #8392A7;}
.dashboard-widget.widget-top-categories .chart-legend {display: flex; flex-wrap: wrap; justify-content: flex-start; margin-top: 15px;}
.dashboard-widget.widget-top-categories .chart-legend .chart-legend-item {display: flex; align-items: center; width: 40%; margin-bottom: 5px;}
.dashboard-widget.widget-top-categories .chart-legend .chart-legend-item .legend-color {width: 20px; height: 20px; border-radius: 3px; margin-right: 5px;}

.dashboard-widget.widget-rating .widget-title {font-size: 14px; color: #8392A7; font-weight: 600; margin: 0 0 7px;}
.dashboard-widget.widget-rating .rating-container {display: flex; align-items: center;}
.dashboard-widget.widget-rating .rating-container .rating-score {font-size: 14px; color: #8392A7; font-weight: 600; margin: 0 0 0 7px;}
.dashboard-widget.widget-top-categories .chart-legend .chart-legend-item {display: flex; align-items: center; flex-wrap: wrap; width: 50%;}
.dashboard-widget.widget-top-categories .chart-legend .chart-legend-item .legend-color {width: 20px; height: 20px; border-radius: 3px; margin-right: 5px;}

.dashboard-widget.widget-sales-history .widget-container {padding-top: 20px;}
.dashboard-widget.widget-sales-history .widget-title {font-size: 26px; font-weight: bold; color: #2D2D2D;  margin: 0 0 15px;}

.dashboard-widget.widget-customer-scans .widget-container {padding-top: 20px;}
.dashboard-widget.widget-customer-scans .widget-title {font-size: 26px; font-weight: bold; color: #2D2D2D;  margin: 0 0 15px;}

.dashboard-widget.widget-revenue-groups .list-item {padding: 10px;}
.dashboard-widget.widget-revenue-groups .list-item .item-name {width: 40%; font-weight: 600; color: #8392A7;}
.dashboard-widget.widget-revenue-groups .list-item .item-total-amount {width: 30%; color: #2D2D2D; font-weight: 600;}
.dashboard-widget.widget-revenue-groups .list-item .item-total-amount small + span {color: #8392A7; font-weight: 800; font-size: 12px; line-height: 12px; display: block;}
.dashboard-widget.widget-revenue-groups .widget-diff-percentage {font-size: 14px; font-weight: 600; color: #14DCA9; margin: 0 0 0 8px;}
.dashboard-widget.widget-revenue-groups .widget-diff-percentage.negative {color: #ED1652;}

@media (min-width: 0) and (max-width: 1280px) {
  .dashboard-scene .top-navigation {flex-direction: column; justify-content: flex-start;}
  .dashboard-scene .top-navigation .dashboard-title {margin-bottom: 15px;}
  .dashboard-scene .top-navigation .dashboard-controls {flex-direction: column; align-items: flex-start; justify-content: flex-start;}
  .dashboard-scene .top-navigation .dashboard-controls > * .compare-text.divider {display: none;}
  .dashboard-scene .top-navigation .dashboard-controls .compare-controls {margin-left: 0;}
  .dashboard-scene .top-navigation .dashboard-controls .company-controls {margin-bottom: 10px;}
  
  .dashboard-scene .dashboard-widgets.row {flex-direction: column;}
  .dashboard-scene .dashboard-widgets .row {flex-direction: column;}
  .dashboard-scene .dashboard-widgets .col-33 {width: 100%}
  .dashboard-scene .dashboard-widgets .col-40 {width: 100%}
  .dashboard-scene .dashboard-widgets .col-50 {width: 100%}
  .dashboard-scene .dashboard-widgets .col-60 {width: 100%}
  .dashboard-scene .dashboard-widgets .col-66 {width: 100%}
}