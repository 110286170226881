// Location row
.location-row {width: 100%; padding: 12px; border: 1px solid #E7ECF4 ; border-radius: 4px; display: flex; align-items: center;}
.location-row.selected {background-color: lighten($color: $blue1, $amount: 46);}
.location-row > * {display: flex; align-items: center;}
.location-row > * p {margin: 0;}
.location-row > * p span {font-weight: 600;}
.location-row .control-col {width: 5%; justify-content: center;}
.location-row .control-col .bp3-checkbox {margin: 0;}
.location-row .status-col {width: 7.5%; justify-content: center;}
.location-row .status-col .bp3-switch {margin: 0;}
.location-row .name-col {width: 30%;}
.location-row .qr-status-col {width: 20%;}
.location-row .actions-col {width: 50%; display: flex; justify-content: flex-end;}

// Location dialog
.location-dialog .form-control {margin-top: 10px;}