// Users list 
.users-list {margin-top: 5px;}
.users-list .user-row {margin-bottom: 5px;}

// User row
.user-row {width: 100%; padding: 12px; border: 1px solid #E7ECF4; border-radius: 4px; display: flex; align-items: center;}
.user-row.selected {background-color: beige;}
.user-row > * {display: flex;}
.user-row > * p {margin: 0;}
.user-row > * p span {font-weight: 600;}
.user-row .col-username {width: 12%; padding-left: 10px;}
.user-row .col-name {width: 15%;}
.user-row .col-role {width: 10%;}
.user-row .col-company {width: 12%;}
.user-row .col-store {width: 16%;}
.user-row .col-status {width: 10%;}
.user-row .col-actions {width: 25%; justify-content: flex-end;}