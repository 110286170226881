// Portal
.bp3-portal {z-index: 999999;}

// Form Groups
.bp3-form-group {margin-bottom: 1rem;}
.bp3-form-group > label.bp3-label {margin-bottom: 4px; font-weight: 600;}

// Inputs
.bp3-input {box-shadow: $bp3-input-box-shadow;}
.bp3-input:focus {box-shadow: $bp3-input-focus-box-shadow;}
.bp3-input-group .bp3-input {box-shadow: $bp3-input-box-shadow;}
.bp3-input-group .bp3-input:focus {box-shadow: $bp3-input-focus-box-shadow;}
.bp3-input:disabled, .bp3-input.bp3-disabled {background-color: $light-gray4; color: $gray1; box-shadow: $bp3-input-box-shadow;}
textarea.bp3-input:read-only, input.bp3-input:read-only {background-color: $light-gray4; color: $gray1; box-shadow: $bp3-input-box-shadow;cursor: not-allowed;}
.bp3-input[type="search"] {border-radius: 3px;}

// Buttons
.bp3-button {box-shadow: none;}
.bp3-button:hover {box-shadow: none;}
.bp3-button:active {box-shadow: none;}

.bp3-button.bp3-intent-primary {box-shadow: none;}
.bp3-button.bp3-intent-primary:hover {box-shadow: none;}
.bp3-button.bp3-intent-primary:active {box-shadow: none;}

.bp3-button.bp3-intent-danger {box-shadow: none;}
.bp3-button.bp3-intent-danger:hover {box-shadow: none;}
.bp3-button.bp3-intent-danger:active {box-shadow: none;}

.bp3-button.bp3-intent-warning {box-shadow: none;}
.bp3-button.bp3-intent-warning:hover {box-shadow: none;}
.bp3-button.bp3-intent-warning:active {box-shadow: none;}

.bp3-button.bp3-intent-success {box-shadow: none;}
.bp3-button.bp3-intent-success:hover {box-shadow: none;}
.bp3-button.bp3-intent-success:active {box-shadow: none;}

.bp3-button.danger-outline {box-shadow: $bp3-input-box-shadow-danger;}
.bp3-button.danger-outline:hover {box-shadow: $bp3-input-box-shadow-danger;}
.bp3-button.round-button {border-radius: 100%;}

// Switch color
.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {background-color: $blue2;}
.bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {background-color: $blue2}
.bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {background-color: $blue2;}
.bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {background-color: $blue2;}
.bp3-control.bp3-switch{ margin-bottom: 0;}

// Checkbox color
// .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator {background-color: $green3;}
// .bp3-control.bp3-checkbox input:checked:disabled ~ .bp3-control-indicator {background-color: rgba(10, 102, 64, .5);}
// .bp3-control.bp3-checkbox input:checked:not(:disabled):active ~ .bp3-control-indicator {background-color: $green1;}
// .bp3-control.bp3-checkbox:hover input:checked ~ .bp3-control-indicator {background-color: $green1;}
// .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator {background-color: $magenta3;}
// .bp3-control.bp3-checkbox input:checked:disabled ~ .bp3-control-indicator {background-color: rgba(237, 22, 82, .5);}
// .bp3-control.bp3-checkbox input:checked:not(:disabled):active ~ .bp3-control-indicator {background-color: $magenta1;}
// .bp3-control.bp3-checkbox:hover input:checked ~ .bp3-control-indicator {background-color: $magenta1;}
.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");}

// Toasts
.bp3-toast-container{z-index: 9999999;}
.bp3-toast.bp3-intent-success {background-color: $green1;}

// Collapse
.bp3-collapse.remove-transform .bp3-collapse-body {transform: none !important;}

// Date range picker
.bp3-daterangepicker .DayPicker-Day--selected-range {background-color: #EBF1F5;}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {background-color: $pt-intent-primary;}
.bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {background-color: #EBF1F5;}

// Callouts
.bp3-callout.bp3-intent-danger {color: $pt-intent-danger;}
.bp3-callout.bp3-intent-primary {background-color: #D8E5F1; color: $blue1;}
.bp3-icon.bp3-icon-link{padding-top: .25rem}

// HTML Select
.bp3-html-select select { background: none; background-color: white; }
.bp3-html-select select:hover { background: none; background-color: white; }

// Dialog
.bp3-dialog {background-color: #FFF;}
.bp3-dialog-body {max-height: 80vh; overflow: auto; padding: 0 4px;}

.bp3-toast-container.bp3-toast-container-inline {position: fixed;}

// Tooltip
.bp3-tooltip2 .bp3-popover2-content {background: #f7f7f7; color: #394b59;}
.bp3-tooltip2 .bp3-popover2-arrow-fill {fill: #f7f7f7;}