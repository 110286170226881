// Menus List
.menus-list .item-text {display: flex !important; align-items: center;}
.menus-list .item-text .omnivore-icon {width: 20px; margin-left: 10px;}

// Menu categories list
.menu-categories-list {display: flex; flex-direction: column;}
.menu-categories-list .spinner {margin: 0 auto;}
.menu-categories-list .add-category-line {display: flex; justify-content: center; align-items: center; margin: 7px 0;}
.menu-categories-list .add-category-line .divider {width: 30%; border-bottom: 1px solid #E7ECF4; margin: 0 20px}

// Menu category accordion
.menu-category-accordion {width: 100%; overflow: hidden; border-radius: 8px !important; box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .16) !important;}
.menu-category-accordion .accordion-header {display: flex; cursor: grab !important;}
.menu-category-accordion .accordion-header .MuiAccordionSummary-content {display: flex; align-items: center;}
.menu-category-accordion .accordion-header .accordion-title {font-size: 18px; font-weight: 800;}
.menu-category-accordion .accordion-header .accordion-spinner {width: 20px !important; height: 20px !important; margin-left: 10px;}
.menu-category-accordion .accordion-header .accordion-menu-button {margin-left: auto;}

.menu-category-accordion .accordion-body {display: flex; flex-direction: column;}
.menu-category-accordion .products-list {display: flex; flex-direction: column; width: 100%;}
.menu-category-accordion .products-list .divider {height: 1px; background-color: #E7ECF4; width: 90%; border: none; }
.menu-category-accordion .products-list .no-products {font-size: 14px; font-weight: 800; margin-left: 5%;}
.menu-category-accordion .products-list .see-all-bar {height: 36px; background-color: #EDF8FF; color: #0D02FF; font-size: 14px; font-weight: bolder; text-align: center; display: flex; align-items: center; justify-content: center; border-radius: 4px; cursor: pointer; position: relative;}
.menu-category-accordion .products-list .see-all-bar * {cursor: pointer;}
.menu-category-accordion .products-list .see-all-bar label {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.menu-category-accordion .products-list .see-all-bar .hidden-items {height: 25px; margin: 0 auto 0 5px; background-color: #BBFFF2; color: black; font-size: 12px; font-weight: bold; border-radius: 6px; display: flex; align-items: center; padding: 0 5px;}
.menu-category-accordion .products-list .see-all-bar .hidden-items .icon {color: #00E0A6; margin-right: 3px;}
.menu-category-accordion .products-list .product-list-item {display: flex; align-items: center; width: 100%; padding: 5px 0; cursor: grab; background-color: #FFF;}
.menu-category-accordion .products-list .product-list-item > * .title {font-size: 14px; font-weight: 800; line-height: 26px;}
.menu-category-accordion .products-list .product-list-item > * .subtitle {font-size: 14px; font-weight: 400; line-height: 26px;}
.menu-category-accordion .products-list .product-list-item > * .description {font-size: 12px; font-weight: 600; color: #8392a7; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 12px;}
.menu-category-accordion .products-list .product-list-item .item-control {width: 5%; text-align: center;}
.menu-category-accordion .products-list .product-list-item .item-switch {width: 6%;}
.menu-category-accordion .products-list .product-list-item .item-name {width: 20%; display: flex; flex-direction: column;}
.menu-category-accordion .products-list .product-list-item .item-name .title {display: flex; flex-direction: row; align-items: center}
.menu-category-accordion .products-list .product-list-item .item-name .title .linked-icon {width: 20px; margin-left: 10px;vertical-align: initial; color: #8392A7;}
.menu-category-accordion .products-list .product-list-item .item-customs {width: 20%; padding-left: 20px;}
.menu-category-accordion .products-list .product-list-item .item-images {width: 20%; display: flex; justify-content: space-evenly;}
.menu-category-accordion .products-list .product-list-item .item-price {width: 15%; display: flex; flex-direction: column;}
.menu-category-accordion .products-list .product-list-item .item-tax {width: 7%;}
.menu-category-accordion .products-list .product-list-item .item-actions {width: 15%; display: flex; justify-content: center;}
.menu-category-accordion .products-list .product-list-item .bp3-switch {margin: 0;}
.menu-category-accordion .products-list .product-list-item .bp3-checkbox {margin: 0;}

.menu-category-accordion .accordion-footer {display: flex; align-items: center; margin-top: 10px; cursor: grab;}
.menu-category-accordion .accordion-footer .add-product-button .icon {margin-right: 4px;}

.menu-category-accordion .page-info {margin-left: auto; display: flex; flex-direction: column;}
.menu-category-accordion .page-info p {color: #8392a7; font-size: 13px; font-weight: 600; line-height: 18px;}
