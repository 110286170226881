// Orders list
.orders-history-scene .orders-list {margin-top: 20px;}
.orders-history-scene .orders-list .order-row {margin-bottom: 5px;}

// Orders table
.orders-table-wrapper {max-width: 100%; overflow: auto;}
.orders-table {margin-top: 20px; width: 100%;}
.orders-table th {background-color: #0D02FF; color: #FFF !important; font-size: 12px; text-align: center !important; vertical-align: middle !important;}
.orders-table th:first-of-type {border-radius: 3px 0 0 0;}
.orders-table th:last-of-type {border-radius: 0 3px 0 0;}
.orders-table td {padding: 10px !important; font-size: 12px; text-align: center !important; vertical-align: middle !important;}

// Order row
.order-row .col-total {font-weight: 600;}
.order-row .col-actions {text-align: left !important;}  

// Order payment method figure
.order-payment-method {width: 50px; border-radius: 3px; overflow: hidden;}
.order-payment-method img {width: 100%; mix-blend-mode: multiply;}
