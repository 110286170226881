.image-uploader {width: 100%; border-radius: 4px; padding: 12px; min-height: 80px; position: relative; overflow: hidden;}

.image-uploader .drop-area {position: absolute; left: 0; top: 0; width: 100%; height: 100%;  border-width: 1px; border-style: dashed; border-color: #cbccce; }
.image-uploader .drop-area.dragging { border-color: $blue1;}

.image-uploader .image-list {display: grid; grid-template-columns: repeat( auto-fill, 100px ); grid-gap: 12px;}
.image-uploader .image-list .image-list-item {position: relative; border-radius: 4px; overflow: hidden; height: 100px; width: 100px; border: 1px solid rgba(167, 182, 194, 0.3);}
.image-uploader .image-list .image-list-item:last-child {margin-right: auto;}
.image-uploader .image-list .image-list-item img {object-fit: cover; object-position: center; width: 100%; height: 100%;}
.image-uploader .image-list .image-list-item .image-loading {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.image-uploader .image-list .image-list-item .image-loading .bp3-button-text {text-align: center;}
.image-uploader .image-list .image-list-item .image-error {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.image-uploader .image-list .image-list-item .image-fader {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, .5);}
.image-uploader .image-list .image-list-item .control-close {position: absolute; top: 0; right: 0;background: white; border-radius: 100%; margin: 0.25rem; color: #2c2c2c}
.image-uploader .image-list .image-list-item .control-close:hover {color: $red1}

.image-uploader .uploader-error {color: $red1; text-align: center;padding: 12px 0 4px 0;}

.image-uploader .bp3-non-ideal-state > * { margin-bottom: 8px;} 
.image-uploader .bp3-non-ideal-state .bp3-button { z-index: 1;} 